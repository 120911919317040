import React from 'react';
import './style.css';
import SectionLayout from '../components/sectionLayout';

function Home() {
  return (
    <div>
      <main style={{ paddingTop: 50 }}>
        <SectionLayout>
          <div className=''>
            <div className='center' style={{ marginBottom: 50}}>
              <a href="/" className="logo-img" alt="Benefits Data Trust">
                <img src="/logo-header-benefitsdatatrust.png" />
              </a>  
            </div>
            
            <p className='p-large center'>MESSAGE FROM BENEFITS DATA TRUST FOUNDER AND BOARD CHAIR WARREN KANTOR</p>

            <p className='p-large'>Benefits Data Trust (BDT) will officially close its doors as planned on August 24, 2024.
            Since our announcement to close, the leadership team and the Board have been working
            tirelessly on two parallel paths: dissolution and potential partnership or acquisition to
            continue to deliver on our mission.</p>

            <p className='p-large'>In the past few weeks, BDT met with many possible partners and funders to discuss any
            possible means of continuing BDT&#39;s mission. As of yesterday, it became clear that we
            had exhausted all avenues and would not be able to find a sustainable way forward for
            the organization in the time remaining. We want to especially recognize some of BDT&#39;s
            long-term funders who have engaged with us so diligently over these past few weeks and
            gave so much of their time and brainpower to this effort: the Bill &amp; Melinda Gates
            Foundation, Ballmer Group, The Rockefeller Foundation, Robin Hood Foundation, Gitlab,
            The Pew Charitable Trusts, and many, many others. We sincerely thank them for all of
            their time and effort during these past few weeks.</p>

            <p className='p-large'>We have been in communication with the PA Attorney General's office in order to dissolve
            in compliance with all nonprofit regulations, including data security, preserving technology
            and other assets. We are working with all necessary parties, including state agencies and
            regulators, to end operations responsibly, with integrity, trust and transparency, just as we
            have done for the past 20 years in serving our clients. There was no malfeasance,
            misappropriation of funds, or fraud that caused BDT to wind down.</p>

            <p className='p-large'>No one wishes to see the nonprofit organization they founded and personally invested in
            for many years cease to operate. This is an emotional and sad time for many employees
            including senior-level executives, Board members, and supporters of BDT. However,
            there are many big impacts, lessons learned, and people helped because it existed. The
            organization brought over $10B in benefits funds to individuals and families in need,
            positively influencing the policy, technology, and administration of benefits programs
            across the United States. The financial distress of the organization just simply could not
            be relieved in a timely manner, which forced our closure.</p>

            <p className='p-large'>BDT began as an organization contracted by state and local governments with a simple
            mission: to help people access their public benefits. The cost to deliver this service was
            not entirely covered by the revenue provided by the contracts, and BDT has always
            needed to rely on philanthropic giving to fill the gaps. We also received grant funding to
            support our policy work and investments in new technology, including AI. But philanthropy
            is unpredictable, not guaranteed, and comes in waves. Like many others, BDT benefitted
            from an influx in funding in COVID that was not sustainable. We received some large
            one-time gifts, and we dared to dream.</p>

            <p className='p-large'>The gap in access to $80 billion in public benefits isn't getting any smaller and requires
            that good people continue to do this good work. A new model is needed to ensure that the
            next generation that picks up the baton where BDT left off can do so with certainty that
            they can grow and make investments without needing to piece funding together.</p>

            <p className='p-large'>I am extremely grateful to the BDT employees past and present, our partners and
            funders, and my fellow Board members who supported BDT's mission and our clients for
            nearly 20 years. We made a difference in the lives of many people and for that I am
            grateful.</p>

            <p className='p-large'>Warren Kantor<br />
            Founder, Benefits Data Trust</p>

            <p className='p-large center' style={{justifyContent: 'center', marginBottom: 50, marginTop: 50,}}>
              <a href='https://docs.google.com/forms/d/e/1FAIpQLSemjTgTtcARFPj_8fZSzAQo1UyKGkNCA0AmxgxxQ6geTeW_qw/viewform' className='defaultBtn buttonBtn' style={{display: 'inline-block', padding: 20}}>
                <span>Contact Us</span>
              </a>
            </p>
          </div>
        </SectionLayout>
      </main>
    </div>
  );
}

export default Home;